import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Navbar.js";
import Posts from "./Posts.js";

function App() {
  return (
    <div className="App min-h-screen bg-gray-100">
      <Navbar />
      <Posts />
    </div>
  );
}

export default App;
