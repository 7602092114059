import React from "react";

function Navbar() {
  return (
    <div className="w-full h-12 bg-gray-50 ">
      <p className="text-center font-bold pt-3">TextShare</p>
    </div>
  );
}

export default Navbar;
