import React, { useEffect, useState } from "react";
import Post from "./Post.js";

function Posts() {
  const [posts, setPosts] = useState([]);

  async function getPosts() {
    const resp = await fetch(
      "https://generalassignment.kchoks.workers.dev/posts"
    );
    const postsResp = await resp.json();
    setPosts(postsResp);
  }

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div className="h-auto bg-gray-100">
      {posts.map((post) => (
        <Post
          refresh={getPosts}
          key={post.key}
          postKey={post.key}
          username={post.username}
          title={post.title}
          content={post.content}
          reactHappy={post.reactions.happy}
          reactSad={post.reactions.sad}
          reactHeart={post.reactions.heart}
          postType={post.type}
        />
      ))}
    </div>
  );
}

export default Posts;
