import React, { useState } from "react";
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  HeartIcon,
} from "@heroicons/react/solid";

function Post(props) {
  const [happyFocus, setHappyFocus] = useState(false);
  const [sadFocus, setSadFocus] = useState(false);
  const [heartFocus, setHeartFocus] = useState(false);

  async function handleReactHappy() {
    setHappyFocus(!happyFocus);
    const data = {
      postKey: props.postKey,
      increment: !happyFocus,
      react: "happy",
    };
    const resp = await fetch(
      "https://generalassignment.kchoks.workers.dev/updatePostReact",
      {
        method: "post",
        body: JSON.stringify(data),
      }
    );
    await props.refresh();
  }

  async function handleReactSad() {
    setSadFocus(!sadFocus);
    const data = { postKey: props.postKey, increment: !sadFocus, react: "sad" };
    const resp = await fetch(
      "https://generalassignment.kchoks.workers.dev/updatePostReact",
      {
        method: "post",
        body: JSON.stringify(data),
      }
    );
    await props.refresh();
  }

  async function handleReactHeart() {
    setHeartFocus(!heartFocus);
    const data = {
      postKey: props.postKey,
      increment: !heartFocus,
      react: "heart",
    };
    const resp = await fetch(
      "https://generalassignment.kchoks.workers.dev/updatePostReact",
      {
        method: "post",
        body: JSON.stringify(data),
      }
    );
    await props.refresh();
  }

  return (
    <div
      className="w-1/2 md:w-1/2 h-1/6 mx-auto my-3 bg-gray-50 font-semibold"
      id={props.postKey}
    >
      <div className="py-2 bg-gray-50 border-gray-300 border-2 rounded-sm text-left px-4">
        <span className="text-blue-600"> {props.title} </span>
        by
        <span className="text-green-600"> {props.username} </span>
      </div>
      <div className="py-2 bg-gray-50">
        <p className="p-4">{props.content}</p>
      </div>
      <div className="py-2 bg-gray-50 border-gray-300 border-2 rounded-sm flex flex-nowrap justify-evenly">
        <div className="inline-block flex items-center">
          <div className="mr-2">
            <EmojiHappyIcon
              className={`h-7 w-7 ${
                happyFocus ? "text-yellow-500" : ""
              } inline align-text-top`}
              onClick={handleReactHappy}
            />
          </div>
          <div>{props.reactHappy}</div>
        </div>
        <div className="inline-block flex items-center">
          <div className="mr-2">
            <EmojiSadIcon
              className={`h-7 w-7 ${
                sadFocus ? "text-blue-500" : ""
              } inline align-text-top`}
              onClick={handleReactSad}
            />
          </div>
          <div>{props.reactSad}</div>
        </div>
        <div className="inline-block flex items-center">
          <div className="mr-2">
            <HeartIcon
              className={`h-7 w-7 ${
                heartFocus ? "text-pink-500" : ""
              } inline align-text-top`}
              onClick={handleReactHeart}
            />
          </div>
          <div>{props.reactHeart}</div>
        </div>
      </div>
    </div>
  );
}

export default Post;
